<template>
  <div class="columns">
    <div class="column">
      <a-select
        :manual-doc="userDoc"
        field="routes.vehicles.where.operator"
        :label="$t('operators.sg')"
        options="operators"
        null-option
        @change="(value) => updateAndFetch('operator', value, true)"
      />
    </div>

    <div class="column" />
  </div>
</template>

<script>
import { keys } from 'lodash'
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      operators: 'operators/items'
    }),
    userDoc () {
      return { module: 'user' }
    },
    where () {
      return this.user.routes.vehicles.where
    }
  },
  methods: {
    keys,
    async updateAndFetch (prop, value, fetch = false) {
      await this.$store.dispatch('user/update', {
        path: `routes.vehicles.where.${prop}`,
        value
      })
      if(fetch) {
        this.$store.dispatch('vehicles/fetchDocs')
      }
    }
  }
}
</script>
