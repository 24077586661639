<template>
  <div>
    <a-card
      :style="{ marginBottom: '1em' }"
      :header="$t('common.search')"
      :editable="false"
    >
      <template
        #header-buttons
      >
        <div class="is-pulled-right is-flex buttons">
          <b-button
            icon-left="filter-remove"
            @click="() => $store.dispatch('vehicles/resetFilters')"
          >
            {{ $t('common.navigation.reset_filters') }}
          </b-button>
          <a-select
            :manual-doc="userDoc"
            field="routes.vehicles.orderBy"
            :label="$t('common.order')"
            :options="keys($store.getters['vehicles/orderOptions'])"
            :labels="$store.getters['vehicles/orderOptions']"
          />
        </div>
      </template>

      <filters />
      <a-input
        :style="{ marginTop: '1em' }"
        :manual-doc="userDoc"
        field="routes.vehicles.search"
        :label="$t('common.quick_search')"
      />
    </a-card>
    <a-table
      :columns="user.routes.vehicles.columns"
      :data="$store.getters['vehicles/displayedItems']"
    />
    <more-docs-button
      module="vehicles"
    />
    <add-doc-button
      :doc-props="{}"
    />
  </div>
</template>

<script>
import ATable from '@/components/ATable'
import MoreDocsButton from '@/components/MoreDocsButton'
import { mapGetters, mapActions } from 'vuex'
import { keys } from 'lodash'
import Filters from './Vehicles/Filters'

export default {
  components: {
    Filters,
    MoreDocsButton
  },
  data() {
    return {
      userDoc: { module: 'user' }
    }
  },
  created () {
    this.$store.dispatch('vehicles/fetchDocs')
  },
  methods: {
    keys
  }
}
</script>
